.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Toastify div,
input,
select,
label,
button {
  font-family: "Poppins", sans-serif !important;
}

.login_form {
  height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login_form label {
  font-size: 20px;

}

.digit-group {
  input {
    width: 80px;
    height: 100px;
    background-color: #48484840;
    border: none;
    line-height: 50px;
    text-align: center;
    font-size: 24px;
    font-weight: 200;
    margin: 0 5px;
    border-radius: 5px;
  }

  input::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    display: none;
  }

  .splitter {
    padding: 0 5px;
    font-size: 24px;
  }
}

.fa-download {
  cursor: pointer;
  float: right;
  transform: translate(-20px, 35px);
}

.fa-print {
  cursor: pointer;
  transform: translate(15px, 30px);
}

.receipt .modal-content {
  width: 160mm !important;
  transform: translateX(-50px);
}

#receipt {
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
  margin: 0 auto;
  width: 148mm;
  background: #FFF;
  padding: 10px;
}

#receipt img {
  height: 300px;
}


#receipt h1 {
  font-size: 3em;
  color: #222;
}

#receipt h2 {
  font-size: 24px;
}

#table h2 {
  font-size: 16px;
}



#receipt h3 {
  font-size: 2.4em;
  font-weight: 300;
  line-height: 2em;
}

#receipt p {
  color: #666;
  line-height: 1.2em;
}

#top,
#mid,
#bot {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #EEE;
}

#mid {
  min-height: 100px;

}

#mid p {
  background: #EEE;
  color: black;
  margin: 1;
  font-size: 16px;
  padding-bottom: 5px;
  margin-bottom: 0;
  text-align: justify;
}

#mid span {
  float: right;
}

#bot {
  min-height: 100px;
}

#top .logo {
  height: 120px;
  width: 120px;
}

#receipt .info {
  display: block;
  margin-left: 0;
}

#receipt .title {
  float: right;
}

#table {
  font-size: 16px;
  padding: 6px 21px;
}

#table>div {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  border-bottom: 1px solid #EEE;
}

#table>div>p {
  color: black;
}

#table tr td:nth-child(2) {
  float: right;
}

#receipt .title p {
  text-align: right;
}

#receipt table {

  width: 100%;
  border-collapse: collapse;
}

#receipt .tabletitle {
  color: black;
  font-size: 16px;
  background: #EEE;
  padding: 8px 6px 0px 6px;
}

#legalcopy {
  margin-top: 5mm;
}

.end {
  font-size: 16px;
  text-align: center;
}

.add-button {
  background-color: greenyellow;
  border-radius: 10px;
  height: 30px;
  width: 30px;
  border: none;
  text-align: center;
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navbar a {
  color: rgba(0, 0, 0, .55) !important;
  text-decoration: none !important;
}

.navbar a:hover,
a:active {
  color: black !important;
}

.navbar a .active {
  color: black !important;
  text-decoration: underline !important;
  text-underline-offset: 10px;
}